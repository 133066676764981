import { Links } from './Constants';

const MAIN_INTRODUCTION = `
This is a Scheme implementation mostly based on [_Revised^5 Report on the Algorithmic Language Scheme_](${Links.r5rs}),
with a few intentional omissisons.

It is (more than) enough to run the metacircular evaluator in [_Structure and Interpretation of Programs_](${Links.sicpMceBookSection}).

The source code for the metacircular evaluator can be found [here](${Links.sicpMceSourceCode}).

The source code for the standard library can be found [here](${Links.stdlibProceduresSourceCode}).

The syntax definitions for the macro-equipped sublanguage can be found [here](${Links.stdlibSpecialSyntaxSourceCode}).

`;

const HOTKEYS_INTRODUCTION = `

---
In the editor on the left, you can use the [_Ace keyboard shortcuts_](${Links.aceHotkeys}) 
and also the [_Source Academy keyboard shortcuts_](${Links.sourceHotkeys}).

`;

const generateSourceDocsLink = (sourceType: string) => {
  switch (sourceType) {
    case 'base':
      return `__You have chosen the base language variant with tail call optimisation and without macros.__`;
    case 'no-tco':
      return `__You have chosen the language variant without tail call optimisation or macros.__`;
    case 'macro':
      return `__You have chosen the language variant with tail call optimisaation and macros.__`;
    default:
      return 'You have chosen an invalid sublanguage. Please pick a sublanguage from the dropdown instead.';
  }
};

const generateIntroductionText = (sourceType: string) => {
  return MAIN_INTRODUCTION + generateSourceDocsLink(sourceType) + HOTKEYS_INTRODUCTION;
};

export const generateSourceIntroduction = (sourceVariant: string) => {
  return generateIntroductionText(`${sourceVariant}`);
};
